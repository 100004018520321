import gql from 'graphql-tag'

export const projectListingFragment = gql`
  fragment ProjectListingFragment on ProjectRecord {
    id
    slug
    title
    subtitle
    linkPreviewVideoListing
    linkQuickViewVideoButton
    imageListing {
      id
      responsiveImage(
        imgixParams: { ar: "4:3", fit: crop, w: 1500, fm: webp }
      ) {
        webpSrcSet
        src
        alt
        title
        height
        width
        sizes
      }
    }
    technologyTag {
      id
      slug
      title
    }
    industryTag {
      id
      slug
      title
    }
  }
`

export const projectFragment = gql`
  fragment ProjectFragment on ProjectRecord {
    id
    slug
    title
    subtitle
    textContent(markdown: true)
    headerLinkVideoPreview
    headerLinkVideoFull
    headerLinkImage {
      id
      responsiveImage(imgixParams: { ar: "16:9", w: 3800, fm: webp }) {
        webpSrcSet
        src
        alt
        title
        height
        width
        sizes
      }
    }
    technologyTag {
      id
      slug
      title
    }
    industryTag {
      id
      slug
      title
    }
  }
`
