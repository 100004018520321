import gsap from 'gsap'
import SplitType from 'split-type'

const pageTransition = {
  name: 'page-transiton',
  mode: 'out-in',
  css: false,
  onBeforeEnter: (el) => {
    if (document.body.hasAttribute('data-menu-overlay-open')) {
      return
    }

    const preloader = document.querySelector(
      '[data-animate="preloader-curtain"]'
    )

    gsap.set(preloader, { scaleY: 1, transformOrigin: 'top' })
  },
  onEnter: async (el, done) => {
    if (document.body.hasAttribute('data-menu-overlay-open')) {
      done()
      return
    }

    const preloader = document.querySelector(
      '[data-animate="preloader-curtain"]'
    )
    const preloaderReveal = el.querySelectorAll(
      '[data-animate="preloader-reveal"]'
    )
    const preloaderOpacity = el.querySelectorAll(
      '[data-animate="preloader-opacity"]'
    )

    const splittingTitle = new SplitType(preloaderReveal, {
      tagName: 'span',
      types: 'lines',
    })

    preloaderReveal.forEach((el) => {
      //console.log(el)
      gsap.set(el, {
        overflow: 'hidden',
      })
      gsap.set(el.querySelectorAll('.line'), {
        // overflow: 'hidden',
        y: '110%',
      })
      /* gsap.set(el.querySelectorAll('.word'), {
        y: '110%',
        skewY: 7,
      }) */
    })

    preloaderOpacity.forEach((el) => {
      gsap.set(el, {
        opacity: 0,
      })
    })

    const tl = gsap.timeline({
      paused: true,
      onComplete: async () => {
        done()
        await nextTick()
        tl.kill()
      },
    })
    tl.to(preloader, {
      scaleY: 0,
      duration: 0.6,
      ease: 'power2.inOut',
    })
    if (splittingTitle.lines?.length) {
      tl.to(splittingTitle.lines, {
        y: '0%',
        ease: 'power4.out',
        stagger: 0.15,
        duration: 0.8,
      })
    }

    tl.add('showContent', '<50%')

    tl.to(
      preloaderOpacity,
      {
        opacity: 1,
        duration: 1,
      },
      'showContent'
    )

    await tl.play()
  },
  onLeave: async (el, done) => {
    if (document.body.hasAttribute('data-menu-overlay-open')) {
      done()
      return
    }

    const preloader = document.querySelector(
      '[data-animate="preloader-curtain"]'
    )
    const duration = 0.6
    const ease = 'power2.inOut'

    gsap.set(preloader, { scaleY: 0, transformOrigin: 'bottom' })

    const tl = gsap.timeline({
      paused: true,
      onComplete: async () => {
        done()
        tl.kill()
      },
    })
    tl.to(preloader, {
      scaleY: 1,
      duration,
      ease,
    })

    await tl.play()
    window.lenis.scrollTo(0, { immediate: true })
  },
}

export default pageTransition
