import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'

export const getMedicalFooter = gql`
  ${responsiveImageFragment}
  query GetMedicalFooter($locale: SiteLocale) {
    medicalFooter(locale: $locale) {
      videoUrl
      title
      text
      image {
        id
        responsiveImage(imgixParams: { fit: crop, w: "1000", fm: webp }) {
          ...ResponsiveImageFragment
        }
      }
      id
      ctaLink
      ctaLabel
    }
  }
`
