import { setSizes, setViewportSizes } from '~/plugins/setup-directives/setup.js'

export default defineNuxtPlugin((nuxtApp) => {
  // Reveal Text
  nuxtApp.vueApp.directive('element-sizes', {
    mounted(el, binding) {
      binding.value.id === 'window' ? setViewportSizes() : setSizes(el, binding)
      window.addEventListener('resize', () => {
        binding.value.id === 'window'
          ? setViewportSizes()
          : setSizes(el, binding)
      })
    },
  })
})
