import gql from 'graphql-tag'

import {
  tagTechnologyFragment,
  tagIndustryFragment,
  tagBlogFragment,
} from '../fragments/tags.js'

export const datoState = gql`
  ${tagTechnologyFragment}
  ${tagIndustryFragment}
  ${tagBlogFragment}
  query DatoState($locale: SiteLocale) {
    allTechnologyTags(locale: $locale) {
      ...TagTechnologyFragment
    }
    allIndustryTags(locale: $locale) {
      ...TagIndustryFragment
    }
    allBlogTags(locale: $locale) {
      ...TagBlogFragment
    }
    _site(locale: $locale) {
      globalSeo {
        siteName
        fallbackSeo {
          description
          title
          image {
            url(imgixParams: { w: "1200", h: "630" })
          }
          twitterCard
        }
        titleSuffix
      }
    }
  }
`
