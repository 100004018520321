import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'

export const getLetsTalk = gql`
  ${seoFragment}
  query GetLetsTalk($locale: SiteLocale) {
    letSTalk(locale: $locale) {
      id
      title
      slug
      seo {
        ...SeoFragment
      }
      steps {
        mp3Voice {
          url
        }
        question
        options {
          value
          label
        }
      }
    }
  }
`
