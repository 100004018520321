import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { revealText } from '~/plugins/animation-directives/reveal-text.js'
import { opacityScrollFader } from '~/plugins/animation-directives/opacity-scroll-fader.js'

export default defineNuxtPlugin((nuxtApp) => {
  // Reveal Text
  nuxtApp.vueApp.directive('anim-reveal-text', {
    mounted(el) {
      revealText(el)
    },
  })

  nuxtApp.vueApp.directive('anim-opacity-fader', {
    mounted(el, binding) {
      opacityScrollFader(el, binding)

      //TODO: Non è bellissima come soluzione ma serve per fare un refresh dopo i caricamenti dei vari elementi della pagina
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 100)
    },
  })
})
