import gql from 'graphql-tag'

export const responsiveImageFragment = gql`
  fragment ResponsiveImageFragment on ResponsiveImage {
    alt
    title
    srcSet
    webpSrcSet
    src
    sizes
    width
    height
    base64
    aspectRatio
    bgColor
  }
`
