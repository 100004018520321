<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <VitePwaManifest />
      <NuxtPage />
    </NuxtLayout>
    <div class="preloader-curtain" data-animate="preloader-curtain"></div>
  </div>
</template>

<script setup>
import { useDatoStore } from '@/store/dato.js'

const { locale } = useI18n()

// TODO: Evitare il watch e quando rispondono implementare https://github.com/nuxt-modules/apollo/discussions/529
await useAsyncData(() => useDatoStore().fetchConfiguration(locale.value))

watch(
  () => locale.value,
  
  
  async () => {
    
    console.log('lang changed', locale.value)
  //await refreshNuxtData()
   await useAsyncData(() => useDatoStore().fetchConfiguration(locale.value))
  // window.location.reload(true)
  }
)

const { lenis } = useLenis()
provide('lenis', lenis)
</script>
