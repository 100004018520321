<template>
  <div>
   
    <NuxtLayout name="error">

<div>
   <NavigationMenu :external="true"/>
   
     
      <div class="page page-error p-top-xxxl-1 p-bottom-xxxl-1 is-text-center">
        <h1 class="title-l-1 m-bottom-1">
          {{
            error.statusCode === 500
              ? $t('errors.500.title')
              : $t('errors.404.title')
          }}
        </h1>


        <div class="page-error__content m-top-m-1">
          <template v-if="error.statusCode === 500">
        <div
          class="text-1 m-bottom-1"
          v-html="$t('errors.500.text')
             
          "
        />
        </template>
        <template v-else>
          <p class="text-1 m-bottom-1" v-html="$t('errors.404.content.title')" />
        </template>
<p class="text-1 m-bottom-1"> {{ $t('errors.404.content.pre')  }}<br>
<ul class="m-top-32px">
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'index' })" external class="link-underline-1"><strong>{{ $t('errors.404.content.home.label')  }}</strong></NuxtLink>{{ $t('errors.404.content.home.text')  }}
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'contacts' })" external class="link-underline-1"><strong>{{ $t('errors.404.content.contact.label')  }}</strong></NuxtLink>{{ $t('errors.404.content.contact.text')  }}
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'expertise' })" external class="link-underline-1"><strong>{{ $t('errors.404.content.expertise.label')  }}</strong></NuxtLink>{{ $t('errors.404.content.expertise.text')  }}
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'blog' })" external class="link-underline-1"><strong>{{ $t('errors.404.content.blog.label')  }}</strong></NuxtLink>{{ $t('errors.404.content.blog.text')  }}
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'studio' })" external class="link-underline-1"><strong>{{ $t('errors.404.content.studio.label')  }}</strong></NuxtLink>{{ $t('errors.404.content.studio.text')  }}
  </li>
</ul>
</p>
        </div>
       <!-- <NuxtLink
          :to="localePath({ name: 'index' })"
          class="button-round-1 is-big is-blue"
          external
          ><span>{{ $t('cta.backToHome') }}</span></NuxtLink
        >-->
      </div>
       <FooterMain :external="true"/>
        <FooterMedical/>
       </div>
    </NuxtLayout>
    <div class="preloader-curtain" data-animate="preloader-curtain"></div>
  </div>
</template>

<script setup>
import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  pageTransition,
})

const fromError = setFromError()

const props = defineProps({
  error: Object,
})
console.error(props.error)

onMounted(() => {
  console.log(fromError.value)
})

onUnmounted(() => {
  fromError.value = true
  console.log(fromError.value)
})

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)
</script>
