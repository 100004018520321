export const useHelpers = () => {
  // Ritorno gli slug per la seo (hreflang etc.) nel formato
  /*
  {
    it: { slug: 'slug-1'},
    en: { slug: 'slug-2'},
  }
  */
  const generateSeoLocaleParams = (localeParams, param) => {
    const params = isRef(localeParams) ? localeParams : ref(localeParams)
    return params.value.reduce((acc, item) => {
      return { ...acc, [item.locale]: { [param]: item.value } }
    }, {})
  }

  const evalMatchMedia = (condition) => {
    const matched = ref(false)

    onMounted(() => {
      checkMedia()
      window.addEventListener('resize', checkMedia)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkMedia)
    })

    const checkMedia = () => {
      const check = window.matchMedia(condition)
      matched.value = check.matches
    }

    return matched
  }

  return {
    generateSeoLocaleParams,
    evalMatchMedia,
  }
}
