import revive_payload_client_cg4Et2TUBV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_H8JaWuQr62 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_f64yoloJub from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dFlwPfTMAR from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.18.1_vite@5.3.4_vue@3.4.32/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_G81yvYBDRO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZSMbfAr9rg from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_p4YX55zuDi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_14vfwJoleY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Wq6z0IOhBV from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.18.1_typescript@5.5.3_vue@3.4.32/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ISZoVMrNgc from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_VOXz7yxWvB from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.3_rollup@4.18.1_vue@3.4.32/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import templates_pwa_client_164f5dba_EZdnQYOxoP from "/opt/build/repo/.nuxt/templates.pwa.client.164f5dba.ts";
import plugin_NrKkhnoxLt from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_rollup@4.18.1_typescript@5.5.3_vue@3.4.32/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import i18n_ZM4WjZmRDk from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.32/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
import tracking_client_fEUopUxxSj from "/opt/build/repo/plugins/tracking.client.js";
export default [
  revive_payload_client_cg4Et2TUBV,
  unhead_H8JaWuQr62,
  router_f64yoloJub,
  _0_siteConfig_dFlwPfTMAR,
  payload_client_G81yvYBDRO,
  navigation_repaint_client_ZSMbfAr9rg,
  check_outdated_build_client_p4YX55zuDi,
  chunk_reload_client_14vfwJoleY,
  plugin_vue3_Wq6z0IOhBV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ISZoVMrNgc,
  plugin_VOXz7yxWvB,
  templates_pwa_client_164f5dba_EZdnQYOxoP,
  plugin_NrKkhnoxLt,
  i18n_ZM4WjZmRDk,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q,
  tracking_client_fEUopUxxSj
]