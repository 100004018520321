import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/validate.js";
import netlify_45redirects_45global from "/opt/build/repo/middleware/netlify-redirects.global.ts";
import redirect_45to_45it_45global from "/opt/build/repo/middleware/redirect-to-it.global.ts";
import remove_45trailing_45slash_45global from "/opt/build/repo/middleware/remove-trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  netlify_45redirects_45global,
  redirect_45to_45it_45global,
  remove_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}