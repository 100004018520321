<template>
  <section ref="footerBlock" class="footer-main">
    <div class="row-1 p-top-m-1 p-bottom-m-1">
      <!-- Riga blocchi testi & CTA -->
      <div class="footer-main-row flex">
        <div class="footer-col know-us">
          <div class="footer-main-heading">
            <h2 class="text-xl-2">{{ $t('footer.main.knowUs') }}</h2>
            <div class="footer-main-heading-cta flex m-top-1">
              <nuxt-link
                :to="
                  localePath({
                    name: 'contacts',
                  })
                "
                class="button-round-1"
                :external="external"
                ><span>{{ $t('cta.contactUs') }}</span>
              </nuxt-link>
            </div>
          </div>
        </div>
        <div v-if="matchMedia" class="footer-col discover-us show-for-large">
          <div class="footer-main-heading">
            <h2 class="text-xl-2">{{ $t('footer.main.discover') }}</h2>
            <div class="footer-main-heading-cta flex m-top-1">
              <nuxt-link
                :to="
                  localePath({
                    name: 'careers',
                  })
                "
                :external="external"
                class="button-round-1"
                ><span>{{ $t('cta.workWithUs') }}</span>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Riga socials - newsletter -->
      <div class="footer-main-row flex">
        <div class="footer-col socials">
          <ul class="flex is-column text-xl-2">
            <li>
              <a
                class="link-underline-reverse-1 click_social"
                target="_blank"
                rel="noopener"
                href="https://www.facebook.com/bluemotion.it/"
                >FACEBOOK</a
              >
            </li>
            <li>
              <a
                class="link-underline-reverse-1 click_social"
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/bluemotion.3d/"
                >INSTAGRAM</a
              >
            </li>
            <li>
              <a
                class="link-underline-reverse-1 click_social"
                target="_blank"
                rel="noopener"
                href="https://www.linkedin.com/company/bluemotion/"
                >LINKEDIN</a
              >
            </li>
            <li>
              <a
                class="link-underline-reverse-1 click_social"
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com/user/BLUEMOTIONsrl"
                >YOUTUBE</a
              >
            </li>
            <li>
              <a
                class="link-underline-reverse-1 click_social"
                target="_blank"
                rel="noopener"
                href="https://vimeo.com/bluemotion3d"
                >VIMEO</a
              >
            </li>
          </ul>

          <ul class="flex is-column m-top-m-1 footer-contacts__list">
            <li>
              <a href="tel:00390498626208" class="link-underline-1"
                >+39 049 86 26 208</a
              >
            </li>
            <li>
              <template v-if="locale === 'it'">
                <a href="mailto:contatto@bluemotion.it" class="link-underline-1"
                >contatto@bluemotion.it</a
              >
              </template>
              <template v-else>
                <a href="mailto:contact@bluemotion.it" class="link-underline-1"
                >contact@bluemotion.it</a
              >
              </template>
              <!--<a :href="$t('footer.main.mailLink')" class="link-underline-1"
                >{{$t('footer.main.mail')}}</a
              >-->
            </li>
          </ul>
        </div>
        <div class="footer-col newsletter show-for-large">
          <h4 class="text-xl-1">NEWSLETTER</h4>
          <p class="m-top-8px">{{ $t('footer.main.newsletter') }}</p>
          <div class="m-top-1">
            <FooterNewsletterFormGestionale />
          </div>
        </div>
      </div>

      <!-- Riga corporate e associati -->
      <div class="footer-main-row flex">
        <div class="footer-col corporate">
          <p v-html="$t('footer.main.corporate')" />
          <div class="footer-main-corporate flex m-top-8px">
            <p v-html="$t('footer.main.copyright', { year: actualYear })" />
            <nuxt-link
              class="link-underline-reverse-1"
              :to="
                localePath({
                  name: 'privacy-policy',
                })
              "
              :external="external"
              ><span>{{ $t('footer.main.privacy') }}</span>
            </nuxt-link>
            <nuxt-link
              class="link-underline-reverse-1"
              :to="
                localePath({
                  name: 'cookie-policy',
                })
              "
              :external="external"
              ><span>Cookie Policy</span>
            </nuxt-link>
            <p>
              <br class="show-for-small" />
              Credits:
              <a
                class="link-underline-reverse-1"
                target="_blank"
                rel="noopener"
                href="https://www.quamm.it/"
                >QUAMM</a
              >
            </p>
          </div>
        </div>
        <div class="footer-col footer-main-associated flex">
          <p>{{ $t('footer.main.associated') }}</p>
          <img
            src="@/assets/img/logos/logo-vna-white.png"
            width="125"
            height="50"
            alt="VNA Logo"
          />
          <img
            src="@/assets/img/logos/logo-mepa-white.png"
            width="173"
            height="50"
            alt="MePA Logo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterMain',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const { locale } = useI18n()

const matchMedia = useHelpers().evalMatchMedia('(min-width: 1000px)')
const actualYear = computed(() => new Date().getFullYear())

const isInView = ref(null)
const footerBlock = ref(null)

const lightScrollTop = setLightScrollTop()
const hideScrollTop = setHideScrollTop()
const lightTheme = setLightTheme()

const route = useRoute()

const props = defineProps({
  external: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => isInView.value,
  (isInView) => {
    if (isInView) {
      document.body.classList?.add('--light-theme')
      lightTheme.value = true
      //console.log('watch view true footer light true')
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
      //console.log('watch view false footer light false')
    }
  }
)

onMounted(() => {
  lightScrollTop.value = false
  hideScrollTop.value = false

  ScrollTrigger.create({
    id: 'scrollFooter',
    start: 'top bottom-=120px',
    end: 'bottom bottom-=60px',
    trigger: footerBlock.value,
    onEnter: () => {
      // isInView.value = true
      lightScrollTop.value = true
      hideScrollTop.value = false
    },
    onEnterBack: () => {
      // isInView.value = true
      lightScrollTop.value = true
      hideScrollTop.value = false
    },
    onLeave: () => {
      // isInView.value = false
      hideScrollTop.value = true
      lightScrollTop.value = false
    },
    onLeaveBack: () => {
      // isInView.value = true
      lightScrollTop.value = false
      hideScrollTop.value = false
    },
  })

  ScrollTrigger.create({
    id: 'scrollFooterMenu',
    start: 'top top+=50px',
    trigger: footerBlock.value,
    onEnter: () => {
      isInView.value = true
      lightTheme.value = true
      //console.log('enter footer light true')
    },
    onEnterBack: () => {
      isInView.value = false
      lightTheme.value = false
      //console.log('enter Back footer light false')
    },
    onLeave: () => {
      isInView.value = false
      lightTheme.value = false
      //console.log('leave footer light false')
    },
    onLeaveBack: () => {
      isInView.value = false
      lightTheme.value = false
      //console.log('leave Back footer light false')
    },
  })
})

onUnmounted(() => {
  ScrollTrigger.refresh()
  lightScrollTop.value = false
  hideScrollTop.value = false
  lightTheme.value = false

  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 800)
  //console.log('unmounted footer light false')
})

watch(
  () => route.path,
  () => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      lightScrollTop.value = false
      hideScrollTop.value = false
      ScrollTrigger.refresh()
    }, 800)

    //ScrollTrigger.getById('scrollFooter')?.kill(true)
  }
)
</script>

<style lang="scss">
@import './style.scss';
</style>
