<template>
  <div class="nav-small__wrap flex is-justify-space-between">
    <!-- Logo -->
    <NuxtLink
      :to="localePath({ name: 'index' })"
      :external="external"
      class="nav-small__logo"
    >
      <img
        :src="isLight ? GlyphLogoWhite : GlyphLogo"
        class="nav-small__logo-glyph"
        height="30"
        :alt="runtimeConfig.public.siteName"
      />
    </NuxtLink>

    <div class="nav-small__list__wrap">
      <!-- Button -->
      <button
        ref="menu"
        class="text-s-1 is-text-uppercase"
        @click="openOverlay"
      >
        {{ $t('menu.menu-nav.menu') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuSmall',
}
</script>

<script setup>
import GlyphLogo from '@/assets/img/ico/logo-glyph-bluemotion.svg'
import GlyphLogoWhite from '@/assets/img/ico/logo-glyph-bluemotion-white.svg'

const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  isLight: Boolean,
  external: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['open-menu'])

// Gestisco Overlay
const openOverlay = () => {
  emit('open-menu')
}

/*import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
const runtimeConfig = useRuntimeConfig()

const nav = ref(null)
const menuButton = ref(null)
const logo = ref(null)
const logoDot = ref(null)
const duration = 0.4

const isHover = ref(false)

watch(
  () => isHover.value,
  (isHover) => {
    if (isHover) {
      animateMenuIn().play()
    } else {
      animateMenuOut().play()
    }
  }
)

onMounted(() => {
  const tl = gsap.timeline({
    ease: 'power2.out',
    paused: true,
  })

  tl.to(logo.value, { x: '-115%' })
  tl.to(logoDot.value, { x: '-148px' }, '<')
  tl.to(
    nav.value,
    {
      x: '100%',
    },
    '<'
  )
  tl.to(menuButton.value, { x: '0%' })

  ScrollTrigger.create({
    start: '100px top',
    onEnter: () => {
      tl.play()
    },
    onLeaveBack: () => {
      tl.reverse()
    },
  })
})

const animateMenuIn = () => {
  const tl = gsap.timeline({
    totalDuration: 0.6,
    ease: 'power2.out',
    paused: true,
  })

  tl.to(menuButton.value, {
    x: '100%',
    autoAlpha: 0,
    duration: 0.1,
  })
  tl.to(nav.value, { x: '0%' })

  return tl
}

const animateMenuOut = () => {
  const tl = gsap.timeline({
    totalDuration: 0.6,
    ease: 'power2.out',
    paused: true,
  })

  tl.to(nav.value, { x: '100%' })
  tl.to(menuButton.value, {
    x: '0%',
    autoAlpha: 1,
    duration: 0.1,
  })

  return tl
}*/
</script>

<style lang="scss">
@import './style.scss';
</style>
