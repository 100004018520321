import gql from 'graphql-tag'

export const getNavigationSubmenus = gql`
  query GetNavigationSubmenus($locale: SiteLocale) {
    allExpertises(locale: $locale) {
      id
      title
      slug
    }
    allTechnologies(
      locale: $locale
      filter: { hideInNavigation: { eq: "false" } }
    ) {
      id
      title
      slug
    }
  }
`
