import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import queries from '@/graphql/dato/queries/index.js'

export const useDatoStore = defineStore('dato', () => {
  //const { locale } = useI18n()

  const technologyTags = ref([])
  const industryTags = ref([])
  const blogTags = ref([])
  const seoFallback = ref({})

  // Restituisco la configurazione completa
  const configuration = computed(() => ({
    technologyTags: technologyTags.value,
    industryTags: industryTags.value,
    blogTags: blogTags.value,
    seoFallback: seoFallback.value,
  }))

  // Estraggo la configurazione
  const fetchConfiguration = async (locale) => {
    try {
      const { data } = await useAsyncQuery(queries.dato.datoState, {
        locale: locale,
      })

      technologyTags.value = data.value?.allTechnologyTags.filter(
        (tag) => tag._allReferencingProjectsMeta.count > 0
      )

      industryTags.value = data.value?.allIndustryTags.filter(
        (tag) => tag._allReferencingProjectsMeta.count > 0
      )

      blogTags.value = data.value?.allBlogTags
      seoFallback.value = data.value?._site?.globalSeo
    } catch (e) {
      console.error(e)
    }
  }

  return {
    configuration,
    fetchConfiguration,
  }
})
