import SplitType from 'split-type'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const revealText = (el) => {
  const duration = 0.8
  const splittingTitle = new SplitType(el, { tagName: 'span', types: 'lines' })

  gsap.set(el, {
    overflow: 'hidden',
  })
  gsap.set(el.querySelectorAll('.line'), {
    // overflow: 'hidden',
    y: '110%',
  })
  /* gsap.set(el.querySelectorAll('.word'), {
    y: '110%',
    skewY: 7,
  }) */

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'bottom bottom ',
    },
    onComplete: () => {
      tl.kill()
    },
  })
  tl.to(splittingTitle.lines, {
    y: '0%',
    ease: 'power4.out',
    stagger: 0.15,
    duration,
  })
}
