export const navMenu = {
  linksMain: [
    {
      key: 'works',
      route: { name: 'projects' },
    },
    {
      key: 'expertise',
      action: 'expertise',
    },
    {
      key: 'technology',
      action: 'technology',
    },
    {
      key: 'studio',
      route: { name: 'studio' },
    },
    {
      key: 'blog',
      route: { name: 'blog' },
    },
    {
      key: 'contacts',
      route: { name: 'contacts' },
    },

  ],
  linksBack: [
    {
      key: 'back',
      class: 'nav-menu__links__separator --small-link --top',
      back: true,
      action: 'main',
    },
  ],
  linksSubMenuExpertise: [
    {
      key: 'expertises',
      class: 'nav-menu__links__separator --bottom --small-link',
      route: { name: 'expertise' },
    },
  ],
  linksSubMenuTechnologies: [
    {
      key: 'technologies',
      class: 'nav-menu__links__separator --bottom --small-link',
      route: { name: 'technology' },
    },
  ],
}
