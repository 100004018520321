import gql from 'graphql-tag'

export const tagTechnologyFragment = gql`
  fragment TagTechnologyFragment on TechnologyTagRecord {
    slug
    title
    id
    _allReferencingProjectsMeta {
      count
    }
  }
`

export const tagIndustryFragment = gql`
  fragment TagIndustryFragment on IndustryTagRecord {
    slug
    title
    id
    _allReferencingProjectsMeta {
      count
    }
  }
`

export const tagBlogFragment = gql`
  fragment TagBlogFragment on BlogTagRecord {
    slug
    title
    id
    _allReferencingArticlesMeta {
      count
    }
  }
`
