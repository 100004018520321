import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const opacityScrollFader = (el, binding) => {
  const container = el
  // const targets = el.querySelectorAll(binding.value.target)
  const targets = el.querySelectorAll('header.content-sticky__header')
  gsap.utils.toArray(targets).forEach((target) => {
    const trigger = container

    gsap.to(target, {
      opacity: 0.1,
      scale: 0.9,
      ease: 'none',
      scrollTrigger: {
        trigger: trigger,
        scrub: true, // smooth animation
        start: 'top top+=5%',
        end: '350px',
        //markers: true
      },
    })
  })
}
